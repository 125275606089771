import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { history, fetchWrapper } from "_helpers";
// create slice

const name = "projects";
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });
// exports

export const projectActions = { ...slice.actions, ...extraActions };
export const projectsReducer = slice.reducer;

// implementation

function createInitialState() {
  return {
    projects: {},
  };
}

function createExtraActions() {
  const baseUrl = `${process.env.REACT_APP_API_URL}/projects`;

  return {
    getAll: getAll(),
    create: create(),
    getByExternalId: getByExternalId(),
    update: update(),
  };

  function getAll() {
    return createAsyncThunk(
      `${name}/getAll`,
      async () => await fetchWrapper.get(baseUrl)
    );
  }

  function create() {
    return createAsyncThunk(
      `${name}/create`,
      async ({ customer, name, summary }) =>
        await fetchWrapper.post(`${baseUrl}`, {
          project: { customer: customer, name: name, summary: summary },
        })
    );
  }

  function getByExternalId() {
    return createAsyncThunk(
      `${name}/getByExternalId`,
      async ({ externalId }) =>
        await fetchWrapper.get(`${baseUrl}/${externalId}`)
    );
  }

  function update() {
    return createAsyncThunk(
      `${name}/update`,
      async ({ externalId, name, summary }) =>
        await fetchWrapper.put(`${baseUrl}/${externalId}`, {
          project: { name: name, summary: summary },
        })
    );
  }
}

function createExtraReducers() {
  return {
    ...getAll(),
    ...create(),
    ...getByExternalId(),
    ...update(),
  };

  function getAll() {
    var { pending, fulfilled, rejected } = extraActions.getAll;
    return {
      [pending]: (state) => {
        state.projects = { loading: true };
      },
      [fulfilled]: (state, action) => {
        state.projects = action.payload;
      },
      [rejected]: (state, action) => {
        state.projects = { error: action.error };
      },
    };
  }

  function create() {
    var { pending, fulfilled, rejected } = extraActions.create;
    return {
      [pending]: (state) => {
        state.projects = { submitting: true };
      },
      [fulfilled]: (state, action) => {
        let externalId = action["payload"]["project"]["external_id"];
        const { from } = history.location.state || {
          from: { pathname: "/projects/" + externalId },
        };
        history.navigate(from);
      },
      [rejected]: (state, action) => {
        state.error = action.error;
      },
    };
  }

  function getByExternalId() {
    var { pending, fulfilled, rejected } = extraActions.getByExternalId;
    return {
      [pending]: (state) => {
        state.projects = { loading: true };
      },
      [fulfilled]: (state, action) => {
        state.projects = action.payload;
      },
      [rejected]: (state, action) => {
        state.projects = { error: action.error };
      },
    };
  }

  function update() {
    var { pending, fulfilled, rejected } = extraActions.update;
    return {
      [pending]: (state) => {
        state.projects = { submitting: true };
      },
      [fulfilled]: (state, action) => {
        console.log("projects updated!");
        // const { from } = history.location.state || { from: { pathname: `/invoices/${action.payload?.external_id}` } };
        // history.navigate(from);
      },
      [rejected]: (state, action) => {
        state.error = action.error;
      },
    };
  }
}
