import { configureStore } from "@reduxjs/toolkit";

import { authReducer } from "./auth.slice";
import { usersReducer } from "./users.slice";
import { invoicesReducer } from "./invoices.slice";
import { modalReducer } from "./modal.slice";
import { projectsReducer } from "./projects.slice";
import { customersReducer } from "./customers.slice";
import { settingsReducer } from "./settings.slice";

export * from "./auth.slice";
export * from "./users.slice";
export * from "./invoices.slice";
export * from "./modal.slice";
export * from "./projects.slice";
export * from "./customers.slice";
export * from "./settings.slice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    users: usersReducer,
    invoices: invoicesReducer,
    modal: modalReducer,
    projects: projectsReducer,
    customers: customersReducer,
    settings: settingsReducer,
  },
});
