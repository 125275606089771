import PageHeader from "_components/PageHeader"
import { CreateProjectForm } from "_components/forms/createProject.form"

export { ProjectCreate }

function ProjectCreate() {
    return (
        <>
            <div className="col-span-full xl:col-span-6 bg-white shadow-lg rounded-sm border border-slate-200 pb-5">
                <PageHeader title="Create a Project" />
                <CreateProjectForm />
            </div>
        </>
    )
}
