import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { modalActions, projectActions, customerActions } from "_store";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useFieldArray } from "react-hook-form";

export { CreateProjectForm };

function CreateProjectForm(props) {
  const validationSchema = Yup.object().shape({
    customer: Yup.string().required("Customer is required"),
    name: Yup.string().required("Project name is required"),
    summary: Yup.string().notRequired(),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { control, register, handleSubmit, formState, getValues } =
    useForm(formOptions);

  const dispatch = useDispatch();
  const { customers } = useSelector((x) => x.customers);
  const [selectedOption, setSelectedOption] = useState("");

  useEffect(() => {
    dispatch(customerActions.getAll());
  }, []);

  const { errors, isSubmitting } = formState;

  function onSubmit({ customer, name, summary }) {
    const params = { customer: customer, name: name, summary: summary };
    return dispatch(projectActions.create(params));
  }

  return (
  <>
    <form className="ml-6 mr-6 mb-6 space-y-8 divide-y divide-gray-200" onSubmit={handleSubmit(onSubmit)}>
    <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
      <div className="space-y-6 sm:space-y-5">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {/* Overview */}
           </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              {/* This information will be included on your Proposals and
              Invoices. */}
            </p>
         </div>
      </div>

      <div className="space-y-6 sm:space-y-5">
        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="first-name"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Customer
          </label>
          <div className="mt-1 sm:col-span-2 sm:mt-0">
            <select
              name="customer"
              className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:max-w-xs sm:text-sm"
              id="grid-state"
              {...register("customer")}
            >
            <option key="select-customer" disabled={true} selected="selected">Select...</option>
            {customers.length &&
              customers.map((customer) => {
                return (
                  <option
                    key={customer.external_id}
                    value={customer.external_id}
                  >
                    {customer.name}
                  </option>
                );
              })}
          </select>
            <div className="text-red-700">
              {errors.customer?.message}
            </div>
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="project-name"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Project Name
          </label>
          <div className="mt-1 sm:col-span-2 sm:mt-0">
            <input
              type="text"
              name="project-name"
              id="project-name"
              {...register("name")}
              className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:max-w-xs sm:text-sm"
            />
            <div className="text-red-700">
              {errors.name?.message}
            </div>
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="project-name"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Summary
          </label>
          <div className="mt-1 sm:col-span-2 sm:mt-0">
            <textarea
              type="text"
              rows="10"
              name="summary"
              id="summary"
              {...register("summary")}
              className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:max-w-xs sm:text-sm"
            />
            <div className="text-red-700">
              {errors.summary?.message}
            </div>
          </div>
        </div>

      </div>
    </div>

    <div className="pt-5">
      <div className="flex justify-end">
        <button
          type="submit"
          className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-lasku-blue py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          disabled={isSubmitting}
        >
          {isSubmitting && (
            <span className="spinner-border spinner-border-sm mr-1"></span>
          )}
          {formState.isSubmitting ? "Creating..." : "Create"}
        </button>
      </div>
    </div>
    </form>
    </>
  );
}
