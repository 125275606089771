import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchWrapper } from "_helpers";

// create slice

const name = "settings";
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

// exports

export const settingsActions = { ...slice.actions, ...extraActions };
export const settingsReducer = slice.reducer;

// implementation

function createInitialState() {
  return {
    settings: {},
  };
}

function createExtraActions() {
  const baseUrl = `${process.env.REACT_APP_API_URL}/settings`;

  return {
    getAll: getAll(),
    update: update(),
    getStripeOnboardingLink: getStripeOnboardingLink(),
  };

  function getAll() {
    return createAsyncThunk(
      `${name}/getAll`,
      async () => await fetchWrapper.get(baseUrl)
    );
  }

  function getStripeOnboardingLink() {
    return createAsyncThunk(
      `${name}/getStripeOnboardingLink`,
      async () => await fetchWrapper.get(`${baseUrl}/stripe_onboarding_link`)
    );
  }

  function update() {
    return createAsyncThunk(
      `${name}/update`,
      async ({
        first_name,
        last_name,
        website,
        company_name,
        phone_number,
        addressable_attributes,
      }) =>
        await fetchWrapper.put(`${baseUrl}`, {
          settings: {
            first_name: first_name,
            last_name: last_name,
            website: website,
            company_name: company_name,
            phone_number: phone_number,
            addressable_attributes: addressable_attributes,
          },
        })
    );
  }
}

function createExtraReducers() {
  return {
    ...getAll(),
    ...update(),
    ...getStripeOnboardingLink(),
  };

  function getAll() {
    var { pending, fulfilled, rejected } = extraActions.getAll;
    return {
      [pending]: (state) => {
        state.settings = { loading: true };
      },
      [fulfilled]: (state, action) => {
        state.settings = action.payload;
      },
      [rejected]: (state, action) => {
        state.settings = { error: action.error };
      },
    };
  }

  function getStripeOnboardingLink() {
    var { pending, fulfilled, rejected } = extraActions.getStripeOnboardingLink;

    return {
      [pending]: (state) => {
        state.settings.stripeOnboardingLink = { loading: true };
      },
      [fulfilled]: (state, action) => {
        window.open(action.payload.url, '_blank', 'noreferrer');
      },
      [rejected]: (state, action) => {
        state.settings.stripeOnboardingLink = { error: action.error };
      },
    };
  }

  function update() {
    var { pending, fulfilled, rejected } = extraActions.update;
    return {
      [pending]: (state) => {
        state.settings = { submitting: true };
      },
      [fulfilled]: (state, action) => {
        console.log("settings updated!");
        // const { from } = history.location.state || { from: { pathname: `/invoices/${action.payload?.external_id}` } };
        // history.navigate(from);
      },
      [rejected]: (state, action) => {
        state.error = action.error;
      },
    };
  }
}
