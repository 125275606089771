import { useState, useEffect } from "react";

export { EditableInput };

function EditableInput(props) {
  const [inputValue, setInputValue] = useState();
  const [isEditing, setEditing] = useState(false);
  let isLoading = props.isLoading;

  function handleEditClick() {
    setEditing(!isEditing);
  }

  function handleSaveClick() {
    setEditing(!isEditing);
    props.callback(inputValue);
  }

  function updateInputValue(e) {
    setInputValue(e.target.value);
  }

  return (
    <div>
      <div className="flex justify-between">
        <label
          htmlFor="field"
          className="block text-sm font-medium text-gray-700"
        >
          {props.label}
        </label>

        <span className="text-sm text-blue-500" id="field-edit">
          {isEditing && (
            <button onClick={handleSaveClick} href="#">
              Save
            </button>
          )}

          {!isEditing && (
            <button onClick={handleEditClick} href="#">
              Edit
            </button>
          )}
        </span>
      </div>
      {isEditing && !props.type && (
        <div className="relative mt-1 rounded-md shadow-sm">
          <input
            name="customer"
            className="block w-full rounded-md border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
            id="grid-last-name"
            type="text"
            placeholder={props.placeholder ?? ""}
            defaultValue={props.value}
            onChange={(e) => updateInputValue(e)}
          />
        </div>
      )}

      {isEditing && props.type === "select" && (
        <select
          id="country"
          name="country"
          autoComplete="country-name"
          className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:max-w-xs sm:text-sm"
          onChange={(e) => updateInputValue(e)}
        >
          <option value="Australia">Australia</option>
          <option value="Canada">Canada</option>
          <option value="United States">United States</option>
        </select>
      )}

      {isLoading && (
        <div className="animate-pulse w-30 bg-gray-300 h-6 rounded-md"></div>
      )}

      {!isEditing && !isLoading && <p>{props.value}</p>}
    </div>
  );
}
