import { useEffect } from "react";
import { formatDate } from "common/formatters";
import { useState } from "react";

export { InvoicePreview };

function InvoicePreview(props) {
    const invoiceData = props.invoiceData;
    const previewUrl = process.env.REACT_APP_API_URL + "/invoices/preview/" + invoiceData.external_id;
    const [isLoading, setIsLoading] = useState(true);

    const iframeStyle = {
      width: "700px",
      border: "0px",
      height: "855px",
      padding: "0px",
      visibility: "visible",
      left: "0px",
      top: "0px",
    }

    function hideSpinner() {
      setIsLoading(false);
    }

    return (
        <>
          { isLoading && (
            <>
            <div className="flex items-center justify-center">
              <div
                className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                role="status">
                <span
                  className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                  >Loading...</span>
              </div>
            </div>
            </>
          )}
          <iframe name="invoice-pdf" style={iframeStyle} className={isLoading ? 'hidden' : '' + "box-border bg-white mt-10 p-20 shadow-lg gray-800 radius-5 border"} src={previewUrl} id="pdf_0" scrolling="no" onLoad={hideSpinner}></iframe>
        </>
    )
}
