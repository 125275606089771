import { React } from "react";
import { ModalContainer } from "../_components/modalContainer";

export { SendInvoiceModal };

function SendInvoiceModal(props) {
    console.log('SEND INVOICE MODAL PROPS : ' + JSON.stringify(props));

    const handleSend = () => {
        console.log('handle send!');
    }


  return (
    <>
      <ModalContainer type="INVOICE_SEND" title="Are you sure?" callback={props.handleClick} />
    </>
  );
}
