import PageHeader from "_components/PageHeader";

import { useSelector, useDispatch } from "react-redux";

import { settingsActions } from "_store";

import { useEffect } from "react";
import { Tabs } from "./Tabs";
import { Spinner } from "_components/Spinner";

export { Integrations };

function Integrations() {
  const dispatch = useDispatch();
  const { settings } = useSelector((x) => x.settings);


  useEffect(() => {
    // eslint-disable-next-line
    dispatch(settingsActions.getAll());
  }, []);

  function handleStripeConnect(e) {
    e.preventDefault();
    dispatch(settingsActions.getStripeOnboardingLink());
  };

  return (
    <>
      <div className="col-span-full xl-col-span-6 bg-white shadow-lg rounded-sm border border-slate-200">
        <PageHeader title="Settings" />

        <Tabs selected="Integrations" />

        {settings && (
          <div
            className="ml-6 mr-6 mb-6 space-y-8 divide-y divide-gray-200"
          >
            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
              <div className="space-y-6 sm:space-y-5">
                <div>
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Integrations
                  </h3>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="stripe-account"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Stripe Integration
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      {settings.loading && (
                        <Spinner />
                      )}

                      {!settings.loading && settings?.stripe_integration == null && (
                        <button onClick={(e) => { handleStripeConnect(e) }}
                        className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-lasku-blue py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                          Setup Stripe Connect</button>
                      )}

                      {!settings.loading && settings?.stripe_integration?.charges_enabled && (
                        <div>
                        <p className="text-sm">Connected - charges enabled</p>

                        <div className="flex flex-1 pt-3">
                          <a href="https://dashboard.stripe.com" className="text-sm text-lasku-blue group flex" target="_blank" rel="noreferrer"> Manage your account
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                            </svg>
                          </a>
                        </div>
                        </div>
                      )}

                      {!settings.loading && !settings?.stripe_integration?.details_submitted && (
                        <button onClick={(e) => { handleStripeConnect(e) }}
                        className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-lasku-blue py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                          Setup Stripe Connect</button>
                      )}
                    </div>
                </div>
              </div>

            </div>

          </div>
        )}
      </div>
    </>
  );
}
