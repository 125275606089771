import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { authActions } from "_store";

export { Verification };

function Verification() {
    const dispatch = useDispatch();
    const params = useParams();

    useEffect(() => {
        const verificationParams = {
            token: params.token
        };

        dispatch(authActions.verify(verificationParams));
      }, []);

    return (
        <>
            <p>Your email has been verified. You will be automatically redirected to the login page. Or, click <a href="/login">here</a> to login.</p>
        </>
    )
}
