export const formatDate = (timestamp) => {
  if (timestamp === undefined || timestamp === null) {
    return "";
  }

  const dtFormat = new Intl.DateTimeFormat("en-CA", {});
  return dtFormat.format(new Date(timestamp));
};

export const formatCurrency = (amount) => {
  if (amount === undefined || amount === null) {
    return "";
  }

  const numFormat = new Intl.NumberFormat("en-CA", {
    style: "currency",
    currency: "CAD",
  });

  return numFormat.format(amount);
};

export const capitalizeString = (string) => {
  if (string === undefined || string === null) {
    return "";
  }

  const lower = string.toLowerCase();
  return string.charAt(0).toUpperCase() + lower.slice(1);
};
