import { useEffect, useState, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { history } from "_helpers";
import { authActions } from "_store";
import { Alert } from "_components";
import logo from '../assets/lasku-icon.png';
import { Spinner } from "_components/Spinner";

export { Login };

function Login() {
  const dispatch = useDispatch();
  const authUser = useSelector((x) => x.auth.user);
  const authError = useSelector((x) => x.auth.error);
  const [isVerified, setIsVerified] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  }

  const query = useQuery();

  useEffect(() => {
    // redirect to home if already logged in
    if (authUser) history.navigate("/");

    if (query.get('source') === 'verification') {
      setIsVerified(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // form validation rules
  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email is required"),
    password: Yup.string().required("Password is required"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  const { register, handleSubmit, formState } = useForm(formOptions);

  const { errors } = formState;

  function onSubmit({ email, password }) {
    setIsSubmitting(true);
    return dispatch(authActions.login({ email, password }));
  }

  return (
      <>
      <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-12 w-auto"
          src={logo}
          alt="Lasku"
        />
        <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">Sign in to your account</h2>
        <p className="mt-2 text-center text-sm text-gray-600">
          Or{' '}
          <a href="register" className="font-medium text-lasku-blue hover:text-lasku-dark-blue">
            register a new account
          </a>
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          { isVerified && (
            <Alert message="Your email has been verified" />
          )}
          <form className="space-y-6" action="#" onSubmit={handleSubmit(onSubmit)}>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email address
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  {...register("email")}
                  className={`form-control ${
                                    errors.email ? "is-invalid" : ""
                                  } block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-lasku-blue-500 focus:outline-none focus:ring-lasku-blue-500 sm:text-sm" `}
                />
                <div className="text-red-500">{errors.email?.message}</div>
              </div>
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                Password
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  {...register("password")}
                  className={`form-control ${
                    errors.password ? "is-invalid" : ""
                                  } block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-lasku-blue-500 focus:outline-none focus:ring-lasku-blue-500 sm:text-sm" `}
                />
                <div className="text-red-500">
                  {errors.password?.message}
                </div>
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-lasku-blue-600 focus:ring-lasku-blue-500"
                />
                <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                  Remember me
                </label>
              </div>

              <div className="text-sm">
                <a href="#" className="font-medium text-lasku-blue hover:text-lasku-dark-blue">
                  Forgot your password?
                </a>
              </div>
            </div>

            <div>
              <button
                disabled={isSubmitting && !authError}
                type="submit"
                className="flex w-full justify-center rounded-md border border-transparent bg-lasku-blue py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-lasku-blue focus:outline-none focus:ring-2 focus:ring-lasku-blue focus:ring-offset-2"
              >

                {isSubmitting && !authError && (
                  <span className="spinner-border border-white spinner-border-sm mr-1"></span>
                )}
                {isSubmitting && !authError ? "Logging in..." : "Sign in"}
              </button>
            </div>
            {authError && (
              <div className="text-red-500 mt-3 mb-0">
                {authError.message}
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  </>
  );
}
