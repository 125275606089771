import { useEffect, useMemo, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import PageHeader from "_components/PageHeader";
import { projectActions } from "_store";
import { Divider, EditableInput, Table } from "_components";
import {
  formatDate,
} from "common/formatters";
import { StatusPill } from "_components/statusPill";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export { ProjectDetail };

function ProjectDetail(props) {
  const dispatch = useDispatch();
  const projectId = useParams();

  const [formData, setFormData] = useState({});
  // const [originalData, setOriginalData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const columns = useMemo(
    () => [
      {
        Header: "Invoice Number",
        accessor: "invoice_number",
      },
      {
        Header: "Amount",
        accessor: "total_value",
      },
      {
        Header: "Due Date",
        accessor: "due_date",
        Cell: (props) => <div> {formatDate(props.value)} </div>,
      },
      {
        Header: "Status",
        accessor: "aasm_state",
        Cell: StatusPill,
      },
      {
        Header: "View",
        accessor: "external_id",
        Cell: (props) => (
          <div>
            {" "}
            <a
              href={"/invoices/" + props.value}
              className="text-lasku-blue hover:text-blue-900"
            >
              View<span className="sr-only">{props.value}</span>
            </a>{" "}
          </div>
        ),
      },
    ],
    []
  );

  const dispatchFetchProjects = useCallback(
    (params) => dispatch(projectActions.getByExternalId(params)),
    [dispatch]
  );

  useEffect(() => {
    console.log('useEffect');
    const params = { externalId: projectId.id };
    dispatchFetchProjects(params).then((pd) => {
      setIsLoading(false);
      setFormData(pd.payload);
      // setOriginalData(pd.payload);
    });
  }, [dispatchFetchProjects]);

  const invoiceData = useMemo(() => formData?.invoices_by_project);

  const updateName = (value) => {
    setFormData((currentFormData) => {
      return {
        ...currentFormData,
        name: value,
      };
    });
  };

  const updateSummary = (value) => {
    setFormData((currentFormData) => {
      return {
        ...currentFormData,
        summary: value,
      };
    });
  };

  const submitProject = useCallback(() => {
    (async () => {
      const params = {
        externalId: formData.external_id,
        name: formData.name,
        summary: formData.summary,
      };
      await dispatch(projectActions.update(params)).then(
        toast.success("Project updated")
      );
    })();
  });

  useEffect(() => {
    console.log('submit useffect');
    if (formData) {
      submitProject();
    }
  }, [formData, submitProject]);

  return (
    <>
      <div className="col-span-full xl:col-span-6 bg-white shadow-lg rounded-sm border border-slate-200 pb-5">
        <PageHeader title="Project" />

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
          theme="light"
        />

        <div className="ml-4 mt-1 mr-4">
          <h4 className="leading-6 text-gray-900 text-l font-semibold pl-2">
            Overview
          </h4>

          <div className="mt-3 mb-3">
            <Divider />
          </div>

          <div className="grid gap-6 mb-6 md:grid-cols-2 ml-4 mt-1 mr-4">
            <EditableInput
              label="Project Name"
              placeholder="Marketing Website 2.0"
              value={formData?.name}
              callback={updateName}
              isLoading={isLoading}
            />

            <div>
              <div className="flex justify-between">
                <label
                  htmlFor="field"
                  className="block text-sm font-medium text-gray-700"
                >
                  Customer
                </label>
              </div>
              {isLoading && (
                <div className="animate-pulse w-30 bg-gray-300 h-6 rounded-md"></div>
              )}
              <p>{formData?.customer?.name}</p>
            </div>

            <EditableInput
              label="Summary"
              placeholder="Revamp the marketing website"
              value={formData?.summary}
              callback={updateSummary}
              isLoading={isLoading}
            />
          </div>

          <div className="mt-3 mb-3">
            <Divider />
          </div>
          <h4 className="leading-6 text-gray-900 text-l font-semibold pl-2">
            Invoices
          </h4>
          <div className="p-3">
            <div className="overflow-x-auto">
              {formData?.invoices_by_project && (
                <Table columns={columns} data={invoiceData} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
