import { useSelector } from "react-redux";

import { Modal } from "../_components/modal";
import ReactModal from "react-modal";

export { ModalContainer };
function ModalContainer(props) {
  const { modal } = useSelector((x) => x.modal);

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  if (!modal.isOpen) {
    return null;
  } else {
    return (
      <div
        className="modal fade fixed top-0 left-0 outline-none overflow-x-hidden overflow-y-auto"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog relative w-auto pointer-events-none">
          <div className="modal-content border-none shadow-lg relative flex flex-col items-center w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
            <ReactModal isOpen={modal.isOpen} style={customStyles}>
              <Modal type={props.type} title={props.title} action={props.callback} />
            </ReactModal>
          </div>
        </div>
      </div>
    );
  }
}
