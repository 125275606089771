import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "_components/PageHeader";

import { settingsActions } from "_store";

export { Dashboard };

function Dashboard() {
  const dispatch = useDispatch();
  const { settings } = useSelector((x) => x.settings);

  const welcomeMessage = () => {
    const welcomeStr = "Welcome back";
    if (
      settings?.first_name !== undefined &&
      settings?.first_name !== "" &&
      settings?.first_name != null
    ) {
      return welcomeStr + ", " + settings?.first_name;
    } else {
      return welcomeStr;
    }
  };

  useEffect(() => {
    dispatch(settingsActions.getAll());
  }, []);

  return (
    <div>
      <div className="relative bg-indigo-200 p-4 sm:p-6 rounded-sm overflow-hidden mb-8">
        <div className="relative">
          <h1 className="text-2xl text-slate-800 font-bold mb-1">
            {welcomeMessage()}
          </h1>
        </div>
      </div>

      <div className="col-span-full xl-col-span-6 bg-white shadow-lg rounded-sm border border-slate-200">
        <PageHeader title="Dashboard" />
      </div>
    </div>
  );
}
