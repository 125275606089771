import { createSlice } from "@reduxjs/toolkit";

// create slice
const name = "modal";
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

// exports
export const modalActions = { ...slice.actions, ...extraActions };
export const modalReducer = slice.reducer;

// implementation
function createInitialState() {
  return {
    modal: {
      isOpen: false,
    },
  };
}

function createReducers() {
  return {
    setOpen,
    setClosed,
  };

  function setOpen(state) {
    state.modal.isOpen = true;
  }

  function setClosed(state) {
    state.modal.isOpen = false;
  }
}

function createExtraActions() {}

function createExtraReducers() {
  return {};
}
