import { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "_components/PageHeader";
import { Table } from "_components";
import { projectActions } from "_store/projects.slice";
import { modalActions } from "_store";
import { ProjectModal } from "./ProjectModal";

export { Project };

function Project() {
  const dispatch = useDispatch();
  const { projects } = useSelector((x) => x.projects);

  const columns = useMemo(
    () => [
      {
        Header: "Project",
        accessor: "name",
      },
      {
        Header: "Customer",
        accessor: "customer.name",
      },
      {
        Header: "Billed to date",
        accessor: "total_value_formatted",
      },
      {
        Header: "View",
        accessor: "external_id",
        Cell: (props) => (
          <div>
            {" "}
            <a
              href={"projects/" + props.value}
              className="text-lasku-blue hover:text-blue-900"
            >
              View<span className="sr-only">{props.value}</span>
            </a>{" "}
          </div>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    dispatch(projectActions.getAll());
  }, []);

  const data = useMemo(() => projects);

  const handleCreate = () => {
    return dispatch(modalActions.setOpen());
  };

  return (
    <>
      <div className="col-span-full xl:col-span-6 bg-white shadow-lg rounded-sm border border-slate-200">
        <PageHeader
          title="Projects"
          actionTitle="Create Project"
          link="/projects/create"
        />

        <div className="p-3">
          <div className="overflow-x-auto">
            <Table columns={columns} data={data} />
          </div>
        </div>

        <ProjectModal />
      </div>
    </>
  );
}
