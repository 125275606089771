import { React } from "react";
import { ModalContainer } from "../_components/modalContainer";

export { ProjectModal };

function ProjectModal() {
  return (
    <>
      <ModalContainer type="PROJECT_CREATE" title="Create Project" />
    </>
  );
}
