import { useEffect, useState, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import PageHeader from "_components/PageHeader";
import { customerActions } from "_store";
import { Divider, EditableInput, Table } from "_components";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  formatDate,
} from "common/formatters";
import { StatusPill } from "_components/statusPill";

export { CustomerDetail };

function CustomerDetail(props) {
  const dispatch = useDispatch();
  const customerId = useParams();

  const [formData, setFormData] = useState({});
  // const [originalData, setOriginalData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const dispatchFetchCustomers = useCallback(
    (params) => dispatch(customerActions.getByExternalId(params)),
    [dispatch]
  );

  const invoiceData = useMemo(() => formData?.invoices_by_customer);

  const columns = useMemo(
    () => [
      {
        Header: "Invoice Number",
        accessor: "invoice_number",
      },
      {
        Header: "Amount",
        accessor: "total_value",
      },
      {
        Header: "Due Date",
        accessor: "due_date",
        Cell: (props) => <div> {formatDate(props.value)} </div>,
      },
      {
        Header: "Status",
        accessor: "aasm_state",
        Cell: StatusPill,
      },
      {
        Header: "View",
        accessor: "external_id",
        Cell: (props) => (
          <div>
            {" "}
            <a
              href={"/invoices/" + props.value}
              className="text-lasku-blue hover:text-blue-900"
            >
              View<span className="sr-only">{props.value}</span>
            </a>{" "}
          </div>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    const params = { externalId: customerId.id };
    dispatchFetchCustomers(params).then((pd) => {
      setIsLoading(false);
      setFormData(pd.payload);
      // setOriginalData(pd.payload);
    });
  }, [dispatchFetchCustomers]);

  const updateName = (value) => {
    setFormData((currentFormData) => {
      return {
        ...currentFormData,
        name: value,
      };
    });
  };

  const updateContactName = (value) => {
    setFormData((currentFormData) => {
      return {
        ...currentFormData,
        contact_name: value,
      };
    });
  };

  const updatePhoneNumber = (value) => {
    setFormData((currentFormData) => {
      return {
        ...currentFormData,
        phone_number: value,
      };
    });
  };

  const updateEmailAddress = (value) => {
    setFormData((currentFormData) => {
      return {
        ...currentFormData,
        email_address: value,
      };
    });
  };

  const updateLine1 = (value) => {
    setFormData((currentFormData) => {
      return {
        ...currentFormData,
        addressable: {
          ...currentFormData.addressable,
          line_1: value,
        },
      };
    });
  };

  const updateLine2 = (value) => {
    setFormData((currentFormData) => {
      return {
        ...currentFormData,
        addressable: {
          ...currentFormData.addressable,
          line_2: value,
        },
      };
    });
  };

  const updateCity = (value) => {
    setFormData((currentFormData) => {
      return {
        ...currentFormData,
        addressable: {
          ...currentFormData.addressable,
          city: value,
        },
      };
    });
  };

  const updateProvince = (value) => {
    setFormData((currentFormData) => {
      return {
        ...currentFormData,
        addressable: {
          ...currentFormData.addressable,
          province: value,
        },
      };
    });
  };

  const updatePostcode = (value) => {
    setFormData((currentFormData) => {
      return {
        ...currentFormData,
        addressable: {
          ...currentFormData.addressable,
          postcode: value,
        },
      };
    });
  };

  const updateCountry = (value) => {
    setFormData((currentFormData) => {
      return {
        ...currentFormData,
        addressable: {
          ...currentFormData.addressable,
          country: value,
        },
      };
    });
  };

  const submitCustomer = useCallback(() => {
    (async () => {
      const params = {
        externalId: formData.external_id,
        name: formData.name,
        contact_name: formData.contact_name,
        phone_number: formData.phone_number,
        email_address: formData.email_address,
        addressable_attributes: formData.addressable,
      };
      await dispatch(customerActions.update(params)).then(
        toast.success("Customer updated")
      );
    })();
  });

  useEffect(() => {
    if (formData) {
      submitCustomer();
    }
  }, [formData, submitCustomer]);

  return (
    <>
      <div className="col-span-full xl:col-span-6 bg-white shadow-lg rounded-sm border border-slate-200 pb-5">
        <PageHeader title="Customer" />

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
          theme="light"
        />

        <div className="ml-4 mt-1 mr-4">
          <h4 className="leading-6 text-gray-900 text-l font-semibold pl-2">
            Contact
          </h4>

          <div className="mt-3 mb-3">
            <Divider />
          </div>

          <div className="grid gap-6 mb-6 md:grid-cols-2 ml-4 mt-1 mr-4">
            <EditableInput
              label="Name"
              placeholder="Apple Inc."
              value={formData?.name}
              callback={updateName}
              isLoading={isLoading}
            />

            <EditableInput
              label="Contact Name"
              placeholder="Tim Cook"
              value={formData?.contact_name}
              callback={updateContactName}
              isLoading={isLoading}
            />

            <EditableInput
              label="Email Address"
              placeholder="tim@apple.com"
              value={formData?.email_address}
              callback={updateEmailAddress}
              isLoading={isLoading}
            />

            <EditableInput
              label="Phone Number"
              placeholder="(555) 123-4567"
              value={formData?.phone_number}
              callback={updatePhoneNumber}
              isLoading={isLoading}
            />
          </div>

          <h4 className="leading-6 text-gray-900 text-l font-semibold pl-2">
            Billing Address
          </h4>

          <div className="mt-3 mb-3">
            <Divider />
          </div>
          <div className="grid gap-6 mb-6 md:grid-cols-2 ml-4 mt-1 mr-4">
            <EditableInput
              label="Line 1"
              placeholder="1 Apple Park Way"
              value={formData?.addressable?.line_1}
              callback={updateLine1}
              isLoading={isLoading}
            />

            <EditableInput
              label="Line 2"
              placeholder="Suite 100"
              value={formData?.addressable?.line_2}
              callback={updateLine2}
              isLoading={isLoading}
            />

            <EditableInput
              label="City"
              placeholder="Cupertino"
              value={formData?.addressable?.city}
              callback={updateCity}
              isLoading={isLoading}
            />

            <EditableInput
              label="State/Province"
              placeholder="California"
              value={formData?.addressable?.province}
              callback={updateProvince}
              isLoading={isLoading}
            />

            <EditableInput
              label="Postal Code"
              placeholder="95014"
              value={formData?.addressable?.postcode}
              callback={updatePostcode}
              isLoading={isLoading}
            />

            <EditableInput
              label="Country"
              value={formData?.addressable?.country}
              callback={updateCountry}
              isLoading={isLoading}
              type="select"
            />
          </div>


          <div className="mt-3 mb-3">
            <Divider />
          </div>
          <h4 className="leading-6 text-gray-900 text-l font-semibold pl-2">
            Invoices
          </h4>
          <div className="p-3">
            <div className="overflow-x-auto">
              {formData?.invoices_by_customer && (
                <Table columns={columns} data={invoiceData} />
              )}
            </div>
          </div>

        </div>
      </div>
    </>
  );
}
