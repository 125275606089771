import { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "_components/PageHeader";
import { invoiceActions, modalActions } from "_store";
import {
  formatDate,
} from "common/formatters";
import { InvoiceModal } from "./InvoiceModal";
import { Table } from "_components";
import { StatusPill } from "_components/statusPill";

export { InvoiceDashboard };

function InvoiceDashboard() {
  const dispatch = useDispatch();
  const { invoices } = useSelector((x) => x.invoices);

  const columns = useMemo(
    () => [
      {
        Header: (props) => <div className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">Invoice Number</div>,
        accessor: "invoice_number",
        Cell: (props) => <div className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell"> {props.value} </div>,
      },
      {
        Header: (props) => <div className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">Project</div>,
        accessor: "project.name",
        Cell: (props) => <div className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell"> {props.value} </div>,
      },
      {
        Header: "Customer",
        accessor: "customer.name",
      },
      {
        Header: "Amount",
        accessor: "grand_total_formatted",
      },
      {
        Header: "Due Date",
        accessor: "due_date",
        Cell: (props) => <div > {formatDate(props.value)} </div>,
      },
      {
        Header: (props) => <div className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">Status</div>,
        accessor: "aasm_state",
        // Cell: StatusPill,
        Cell: (props) => <div className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell"> <StatusPill value={props.value} /> </div>,
      },
      {
        Header: "View",
        accessor: "external_id",
        Cell: (props) => (
          <div>
            <a
              href={"invoices/" + props.value}
              className="text-lasku-blue hover:text-blue-900"
            >
              View<span className="sr-only">{props.value}</span>
            </a>
          </div>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    dispatch(invoiceActions.getAll());
  }, []);

  const data = useMemo(() => invoices);

  return (
    <>
      <div className="col-span-full xl:col-span-6 bg-white shadow-lg rounded-sm border border-slate-200">
        <PageHeader
          title="Invoices"
          actionTitle="Create Invoice"
          link="/invoices/create"
        />
        <div className="p-3">
          <div className="overflow-x-auto">
            <Table columns={columns} data={data} />
          </div>
        </div>

        <InvoiceModal />
      </div>
    </>
  );
}
