import PageHeader from "_components/PageHeader"
import { CreateCustomerForm } from "_components/forms/createCustomer.form"

export { CustomerCreate }

function CustomerCreate() {
    return (
        <>
            <div className="col-span-full xl:col-span-6 bg-white shadow-lg rounded-sm border border-slate-200 pb-5">
                <PageHeader title="Add a new Customer" />
                <CreateCustomerForm />
            </div>
        </>
    )
}
