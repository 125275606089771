import { CreateInvoiceForm } from "_components/forms/createInvoice.form"
import PageHeader from "_components/PageHeader"

export { InvoiceCreate }

function InvoiceCreate() {
    return (
        <>
            <div className="col-span-full xl:col-span-6 bg-white shadow-lg rounded-sm border border-slate-200 pb-5">
                <PageHeader title="Create an Invoice" />
                <CreateInvoiceForm />
            </div>
        </>
    )
}
