import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useCallback, useState } from "react";
import { invoiceActions, modalActions } from "_store";
import PageHeader from "_components/PageHeader";
import { Fragment } from "react";
import ButtonDropdown from "_components/ButtonDropdown";
import { InvoiceModal } from "./InvoiceModal";
import { SendInvoiceModal } from "./SendInvoiceModal";
import {
  formatDate,
} from "common/formatters";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { InvoicePreview } from "./InvoicePreview";
import { settingsActions } from "_store";

export { InvoiceDetail };

function InvoiceDetail(props) {
  const dispatch = useDispatch();
  const invoiceId = useParams();
  const { invoices } = useSelector((x) => x.invoices);
  const { settings } = useSelector((x) => x.settings);
  const authUser = useSelector((x) => x.auth.user);
  const [showEditCustomer, setShowEditCustomer] = useState(true);

  useEffect(() => {
    const params = { externalId: invoiceId.id };
    dispatch(invoiceActions.getByExternalId(params));
    dispatch(settingsActions.getAll());
  }, []);

  const showSendModal = () => {
    dispatch(modalActions.setOpen());
  }

  const handleSend = useCallback(() => {
    (async () => {
      const params = { externalId: invoiceId.id };
      const result = await dispatch(invoiceActions.sendInvoice(params));
      dispatch(modalActions.setClosed());

      if (result.error) {
        toast.error("Invoice could not be sent, please try again later.");
      } else {
        toast.success("Invoice sent!");
      }
    })();
  });

  function getPageHeader() {
    if (invoices?.aasm_state === "draft") {
      return (
        <PageHeader
          title="Review Invoice"
          handleClick={showSendModal}
          actionTitle="Send Invoice"
        />
      );
    } else {
      return <PageHeader title="Sent Invoice" />;
    }
  }

  return (
    <>
      <div className="col-span-full xl:col-span-6 bg-white shadow-lg rounded-sm border border-slate-200 pb-5 mb-5 h-full">
        {getPageHeader()}

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
          theme="light"
        />

        <div className="grid grid-flow-col gap-1">
          <div className="px-6 pt-6 pb-4 col-span-3">
            { invoices?.aasm_state !== 'draft' && (
              <p>Sent at: {formatDate(invoices?.sent_at)}</p>
            )}

            { invoices?.aasm_state === "draft" && (
            <div>
            <div className="flex justify-between">
              <label
                htmlFor="field"
                className="block text-lg font-medium text-gray-700"
              >
                Invoice Settings
              </label>
              <span className="text-sm text-blue-500" id="field-edit">
                <ButtonDropdown
                  label="Edit"
                  options={[
                    { label: "Change Customer", action: "" },
                    { label: "Edit Customer", action: "" },
                    { label: "Edit Line Items", action: "" },
                  ]}
                />
              </span>
            </div>
          </div>
            ) }

            <div className="mt-5">
              <div className="flex justify-between">
                <label
                  htmlFor="field"
                  className="block text-sm font-medium text-gray-700"
                >
                  Customer
                </label>
              </div>
              <div className="mt-1">
                {invoices && (
                  <p>{invoices.customer?.name}</p>
                )}
              </div>
            </div>

            <div className="mt-5">
              <div className="flex justify-between">
                <label
                  htmlFor="field"
                  className="block text-sm font-medium text-gray-700"
                >
                  Project
                </label>
              </div>
              <div className="mt-1">
                {invoices && (
                  <p>{invoices.project?.name}</p>
                )}
              </div>
            </div>

            <div className="mt-5">
              <div className="flex justify-between">
                <label
                  htmlFor="field"
                  className="block text-sm font-medium text-gray-700"
                >
                  Due Date
                </label>
              </div>
              <div className="mt-1">
                {invoices && (
                  <p>{formatDate(invoices.due_date)}</p>
                )}
              </div>
            </div>

            <div className="mt-5">
              <div className="flex justify-between">
                <label
                  htmlFor="field"
                  className="block text-sm font-medium text-gray-700"
                >
                  Line Items
                </label>
              </div>
              <div className="mt-1">
                {invoices &&
                  invoices.line_items?.map((line_item) => (
                    <p>
                      {line_item.description} {line_item.rate} x{" "}
                      {line_item.quantity}
                    </p>
                  ))}
              </div>
            </div>
          </div>
          <div className="px-4 sm:px-6 lg:px-8 col-span-5 bg-gray-100 box-border h-screen mb-5">
            <h1 className="text-xl font-semibold text-gray-900 mt-5">Preview</h1>
                <InvoicePreview invoiceData={invoices} />
          </div>
        </div>

        <SendInvoiceModal meow="meow" handleClick={handleSend} />
      </div>
    </>
  );
}
