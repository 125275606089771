import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { invoiceActions, modalActions } from "_store";
import { projectActions } from "_store/projects.slice";
import { customerActions } from "_store";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useFieldArray } from "react-hook-form";
import { useState } from "react";

export { CreateInvoiceForm };

function CreateInvoiceForm(props) {
  const validationSchema = Yup.object().shape({
    customer: Yup.string().required('Customer is required'),
    project: Yup.string(),
    dueDate: Yup.string().required("Due date is required"),
    lineItems: Yup.array().of(
      Yup.object().shape({
        qty: Yup.number().required("Quantity is required"),
        description: Yup.string().required("Description is required"),
        rate: Yup.string().required("Rate is required"),
      })
    ),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { control, register, handleSubmit, formState, watch } =
    useForm(formOptions);
  const { fields, append, update, remove, } =
    useFieldArray({
      control, // control props comes from useForm (optional: if you are using FormContext)
      name: "lineItems", // unique name for your Field Array
    });

  const dispatch = useDispatch();
  const { projects } = useSelector((x) => x.projects);
  const { customers } = useSelector((x) => x.customers);
  const [filteredProjects, setFilteredProjects] = useState({});

  const selectedCustomer = watch('customer');

  useEffect(() => {
    dispatch(customerActions.getAll());
    dispatch(projectActions.getAll());
  }, []);

  useEffect(() => {
    if (projects.length) {
      setFilteredProjects(projects.filter(project => project.customer.external_id === selectedCustomer));
    }
  }, [selectedCustomer]);

  const { errors, isSubmitting } = formState;

  function onSubmit({ customer, project, dueDate, lineItems }) {
    const params = { customer: customer, project: project, dueDate: dueDate, lineItems: lineItems };
    dispatch(invoiceActions.create(params));
  }

  const LineItem = ({ update, index, value, control }) => {
    const { register, handleSubmit } = useForm({
      defaultValues: value,
    });

    return (
      <div className="w-full mb-5">
        <input
          placeholder="qty"
          // eslint-disable-next-line
          {...register(`qty`, { required: true })}
          className="appearance-none w-20 bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mr-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          id="number"
          type="number"
          placeholder="Qty"
        />

        <input
          className="appearance-none w-80 bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mr-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          id="description"
          type="text"
          placeholder="Development work"
          // eslint-disable-next-line
          {...register(`description`, { required: true })}
        />

        <input
          className="appearance-none w-50 bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mr-5 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          id="rate"
          type="number"
          placeholder="115.00"
          {...register(`rate`, { required: true })}
        />
        <div className="text-red-700">
          {errors.lineItems?.[index]?.rate?.message}
        </div>

        <button
          className="inline-block px-7 py-3 text-lasku-blue font-medium text-sm leading-snug uppercase hover:text-black transition duration-150 ease-in-out"
          type="button"
          onClick={handleSubmit((data) => {
            update(index, data);
          })}
        >
          Save
        </button>

        <button
          className="inline-block px-7 py-3 text-lasku-blue font-medium text-sm leading-snug uppercase hover:text-black transition duration-150 ease-in-out"
          type="button"
          onClick={() => remove(index)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-5 h-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
            />
          </svg>
        </button>
      </div>
    );
  };

  return (
    <form className="ml-6 mr-6 mb-6 space-y-8 divide-y divide-gray-200" onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div className="space-y-6 sm:space-y-5">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {/* Overview */}
           </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              {/* This information will be included on your Proposals and
              Invoices. */}
            </p>
         </div>
      </div>

      <div className="space-y-6 sm:space-y-5">
      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="first-name"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Customer
          </label>
          <div className="mt-1 sm:col-span-2 sm:mt-0">
            <select
              name="customer"
              className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:max-w-xs sm:text-sm"
              id="grid-state"
              {...register("customer")}
            >
            <option key="select-customer" disabled={true} selected="selected" value="">Select...</option>
            {customers.length &&
              customers.map((customer) => {
                return (
                  <option
                    key={customer.external_id}
                    value={customer.external_id}
                  >
                    {customer.name}
                  </option>
                );
              })}
          </select>
            <div className="text-red-700">
              {errors.customer?.message}
            </div>
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
            <label
              htmlFor="project"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Project
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <select
                name="project"
                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:max-w-xs sm:text-sm"
                id="grid-state"
                {...register("project")}
              >
              <option key="select-project" selected="selected" value="null">Select...</option>
              {filteredProjects.length &&
                filteredProjects.map((project) => {
                  return (
                    <option
                      key={project.external_id}
                      value={project.external_id}
                    >
                      {project.name} ({project.customer.name})
                    </option>
                  );
                })}
              </select>
              <div className="text-red-700">{errors.project?.message}</div>
            </div>
          </div>

          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
            <label
              htmlFor="project"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Due date
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
            <input
              name="dueDate"
              className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:max-w-xs sm:text-sm"
              id="grid-last-name"
              type="date"
              {...register("dueDate")}
            />
              <div className="text-red-700">{errors.dueDate?.message}</div>
            </div>
          </div>

          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
            <label
              htmlFor="project"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Line Items
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
            {fields.map((field, index) => (
              <fieldset key={field.id}>
                <LineItem
                  control={control}
                  update={update}
                  index={index}
                  value={field}
                />
              </fieldset>
            ))}

            <button
              onClick={(e) => {
                e.preventDefault();
                append({
                  qty: "",
                  description: "",
                  rate: "",
                });
              }}
              className="inline-block px-7 py-3 text-lasku-blue font-medium text-sm leading-snug uppercase hover:text-black transition duration-150 ease-in-out"
            >
              Add Line Item
            </button>
              <div className="text-red-700">{errors.dueDate?.message}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-5">
      <div className="flex justify-end">
        <button
          type="submit"
          className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-lasku-blue py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          disabled={isSubmitting}
        >
          {isSubmitting && (
            <span className="spinner-border spinner-border-sm mr-1"></span>
          )}
          {formState.isSubmitting ? "Creating..." : "Create and Review"}
        </button>
      </div>
    </div>
    </form>
  );
}
