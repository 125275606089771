import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import logo from '../assets/lasku-icon.png';

import { history } from "_helpers";
import { authActions } from "_store";

export { Register };

function Register() {
  const dispatch = useDispatch();
  const authUser = useSelector((x) => x.auth.user);
  const authError = useSelector((x) => x.auth.error);

  useEffect(() => {
    // redirect to home if already logged in
    if (authUser) history.navigate("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // form validation rules
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    email: Yup.string().required("Email is required"),
    password: Yup.string().required("Password is required"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors, isSubmitting } = formState;
  const [ isSuccess, setIsSuccess ] = useState(false);

  function onSubmit({ email, password, first_name, last_name }) {
    (async () => {
      const result = await dispatch(authActions.register({ email, password, first_name, last_name }));

      if (result.payload.user) {
        setIsSuccess(true);
      }
    })();
  }

  return (
    <>
    <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div className="sm:mx-auto sm:w-full sm:max-w-md">
      <img
        className="mx-auto h-12 w-auto"
        src={logo}
        alt="Your Company"
      />
      <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">Create your account</h2>
      <p className="mt-2 text-center text-sm text-gray-600">
        Or{' '}
        <a href="login" className="font-medium text-lasku-blue hover:text-lasku-blue">
          sign into your account
        </a>
      </p>
    </div>

    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <form className="space-y-6" action="#" onSubmit={handleSubmit(onSubmit)} autoComplete="on">
        <div>
            <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">
              First Name
            </label>
            <div className="mt-1">
              <input
                id="first_name"
                name="first_name"
                type="text"
                autoComplete="given-name"
                required
                {...register("first_name")}
                className={`form-control ${
                                  errors.first_name ? "is-invalid" : ""
                                } block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-lasku-blue focus:outline-none focus:ring-lasku-blue sm:text-sm" `}
              />
              <div className="text-red-500">{errors.first_name?.message}</div>
            </div>
          </div>

          <div>
            <label htmlFor="last_name" className="block text-sm font-medium text-gray-700">
              Last Name
            </label>
            <div className="mt-1">
              <input
                id="last_name"
                name="last_name"
                type="text"
                autoComplete="family-name"
                required
                {...register("last_name")}
                className={`form-control ${
                                  errors.last_name ? "is-invalid" : ""
                                } block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-lasku-blue focus:outline-none focus:ring-lasku-blue sm:text-sm" `}
              />
              <div className="text-red-500">{errors.last_name?.message}</div>
            </div>
          </div>

          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Email address
            </label>
            <div className="mt-1">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                {...register("email")}
                className={`form-control ${
                                  errors.email ? "is-invalid" : ""
                                } block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-lasku-blue focus:outline-none focus:ring-lasku-blue sm:text-sm" `}
              />
              <div className="text-red-500">{errors.email?.message}</div>
            </div>
          </div>

          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
              Password
            </label>
            <div className="mt-1">
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                {...register("password")}
                className={`form-control ${
                  errors.password ? "is-invalid" : ""
                } "block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-lasku-blue focus:outline-none focus:ring-lasku-blue sm:text-sm" `}
              />
              <div className="text-red-500">
                {errors.password?.message}
              </div>
            </div>
          </div>

          <div>
            <button
              disabled={isSubmitting || isSuccess}
              type="submit"
              className="flex w-full justify-center rounded-md border border-transparent bg-lasku-blue py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-lasku-blue focus:outline-none focus:ring-2 focus:ring-lasku-blue focus:ring-offset-2"
            >
              {isSubmitting && (
                <span className="spinner-border spinner-border-sm mr-1"></span>
              )}
              Create Account
            </button>
          </div>

          { isSuccess && (
            <div className="text-green-600 mt-3">
            <p>We've sent a confirmation to the email address you provided. Click the confirmation link to continue. </p>
            </div>
          )}

          {authError && (
            <div className="text-red-500 mt-3 mb-0">
              {authError.message}
            </div>
          )}
        </form>
      </div>
    </div>
  </div>
  </>
  );
}
