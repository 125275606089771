import { React } from "react";
import { ModalContainer } from "../_components/modalContainer";

export { InvoiceModal };

function InvoiceModal() {
  return (
    <>
      <ModalContainer type="INVOICE_CREATE" title="Create Invoice" />
    </>
  );
}
