import { useTable } from "react-table";

export { Table };

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function StatusPill({ value }) {
  const status = value ? value.toLowerCase() : "unknown";

  return (
    <span
      className={classNames(
        "px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm",
        status.startsWith("paid") ? "bg-green-100 text-green-700" : null,
        status.startsWith("draft") ? "bg-yellow-100 text-yellow-700" : null,
        status.startsWith("overdue") ? "bg-red-100 text-red-700" : null
      )}
    >
      {status}
    </span>
  );
}

function Table(props) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns: props.columns,
      data: props.data.length ? props.data : [],
    });

  return (
    <table className="min-w-full divide-y divide-gray-300" {...getTableProps()}>
      <thead className="bg-gray-50">
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                {...column.getHeaderProps()}
              >
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody
        className="divide-y divide-gray-200 bg-white"
        {...getTableBodyProps()}
      >
        {props.data.loading && (
          <tr>
            {props.columns.map((col, i) => {
              return (
                <td>
                  <div className="px-2 py-2">
                    <div className="animate-pulse w-30 bg-gray-300 h-6 rounded-md"></div>
                  </div>
                </td>
              );
            })}
          </tr>
        )}
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps({ onClick: () => {} })}>
              {row.cells.map((cell) => {
                return (
                  <td
                    className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>

      {!props.data.loading && rows.length === 0 && (
        <p>Nothing has been added yet.</p>
      )}

      {props.data.error && (
        <div className="text-danger">
          Error loading data: {props.data.error.message}
        </div>
      )}
    </table>
  );
}
