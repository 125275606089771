import { useEffect } from "react";

export { Tabs };

function Tabs(props) {

    const tabs = [
        { name: 'General', href: '/settings', current: props.selected === 'General' },
        { name: 'Integrations', href: '/settings/integrations', current: props.selected === 'Integrations' },
        { name: 'Billing', href: '/settings/billing', current: props.selected === 'Billing' },
      ];

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    return (
        <>
        <div className="lg:hidden">
        <label htmlFor="selected-tab" className="sr-only">
          Select a tab
        </label>
        <select
          id="selected-tab"
          name="selected-tab"
          className="mt-1 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-lasku-blue sm:text-sm sm:leading-6"
          defaultValue={tabs.find((tab) => tab.current).name}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden lg:block ml-5 mb-5">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8">
            {tabs.map((tab) => (
              <a
                key={tab.name}
                href={tab.href}
                className={classNames(
                  tab.current
                    ? 'border-lasku-blue text-lasku-blue'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium'
                )}
              >
                {tab.name}
              </a>
            ))}
          </nav>
        </div>
      </div>
      </>
    )
}
