function PageHeader(props) {

  return (
    <div className="border-gray-200 bg-white px-4 py-5 sm:px-6">
      <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
        <div className="ml-4 mt-4">
          <h3 className="leading-6 text-gray-900 text-xl font-semibold">
            {props.title}
          </h3>
        </div>
        {props.link && (
          <div className="ml-4 mt-4 flex-shrink-0">
            <a
              className="relative inline-flex items-center rounded-md border border-transparent bg-lasku-blue px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              href={props.link}
            >
              {props.actionTitle}
            </a>
          </div>
        )}
        {props.handleClick && (
          <div className="ml-4 mt-4 flex-shrink-0">
              <button
                className="relative inline-flex items-center rounded-md border border-transparent bg-lasku-blue px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                onClick={props.handleClick}
                >
                {props.actionTitle}
              </button>
            </div>
        )}
      </div>
    </div>
  );
}

export default PageHeader;
