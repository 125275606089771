import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { history, fetchWrapper } from "_helpers";

// create slice

const name = "invoices";
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

// exports

export const invoiceActions = { ...slice.actions, ...extraActions };
export const invoicesReducer = slice.reducer;

function createInitialState() {
  return {
    invoices: {},
  };
}

function createExtraActions(payload) {
  const baseUrl = `${process.env.REACT_APP_API_URL}/invoices`;

  return {
    getAll: getAll(),
    create: create(),
    getByExternalId: getByExternalId(),
    sendInvoice: sendInvoice(),
  };

  function getAll() {
    return createAsyncThunk(
      `${name}/getAll`,
      async () => await fetchWrapper.get(baseUrl)
    );
  }

  function create() {
    return createAsyncThunk(
      `${name}/create`,
      async ({ customer, project, dueDate, lineItems }) =>
        await fetchWrapper.post(`${baseUrl}`, {
          invoice: { customer: customer, project: project, dueDate: dueDate, lineItems: lineItems },
        })
    );
  }

  function getByExternalId() {
    return createAsyncThunk(
      `${name}/getByExternalId`,
      async ({ externalId }) =>
        await fetchWrapper.get(`${baseUrl}/${externalId}`)
    );
  }

  function sendInvoice() {
    return createAsyncThunk(
      `${name}/send_invoice`,
      async ({ externalId }) =>
        await fetchWrapper.post(`${baseUrl}/send_invoice`, {
          invoice: { external_id: externalId },
        })
    );
  }
}

function createExtraReducers(payload) {
  return {
    ...getAll(),
    ...create(),
    ...getByExternalId(),
    ...sendInvoice(),
  };

  function getAll() {
    var { pending, fulfilled, rejected } = extraActions.getAll;
    return {
      [pending]: (state) => {
        state.invoices = { loading: true };
      },
      [fulfilled]: (state, action) => {
        state.invoices = action.payload;
      },
      [rejected]: (state, action) => {
        state.invoices = { error: action.error };
      },
    };
  }

  function create() {
    var { pending, fulfilled, rejected } = extraActions.create;
    return {
      [pending]: (state) => {
        state.invoices = { submitting: true };
      },
      [fulfilled]: (state, action) => {
        const { from } = history.location.state || {
          from: { pathname: `/invoices/${action.payload?.external_id}` },
        };
        history.navigate(from);
      },
      [rejected]: (state, action) => {
        state.error = action.error;
      },
    };
  }

  function getByExternalId() {
    var { pending, fulfilled, rejected } = extraActions.getByExternalId;
    return {
      [pending]: (state) => {
        state.invoices = { loading: true };
      },
      [fulfilled]: (state, action) => {
        state.invoices = action.payload;
      },
      [rejected]: (state, action) => {
        state.invoices = { error: action.error };
      },
    };
  }

  function sendInvoice() {
    var { pending, fulfilled, rejected } = extraActions.sendInvoice;
    return {
      [pending]: (state) => {
        state.invoices = { sending: true };
      },
      [fulfilled]: (state, action) => {
        console.log('result: ' + JSON.stringify(action.payload));
      },
      [rejected]: (state, action) => {
        state.invoices = { error: action.error };
      },
    };
  }
}
