import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { modalActions, customerActions } from "_store";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

export { CreateCustomerForm };

function CreateCustomerForm(props) {
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    customer: Yup.string().required("Customer is required"),
    contact_name: Yup.string(),
    email_address: Yup.string().required("Email address is required"),
    phone_number: Yup.string(),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState } = useForm(formOptions);

  useEffect(() => {
    dispatch(customerActions.getAll());
  }, []);

  const { errors, isSubmitting } = formState;

  function onSubmit({ customer, contact_name, email_address, phone_number }) {
    const params = {
      customer: customer,
      contact_name: contact_name,
      email_address: email_address,
      phone_number: phone_number,
    };
    return dispatch(customerActions.create(params));
  }

  return (
    <form className="ml-6 mr-6 mb-6 space-y-8 divide-y divide-gray-200" onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div className="space-y-6 sm:space-y-5">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {/* Overview */}
           </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              {/* This information will be included on your Proposals and
              Invoices. */}
            </p>
         </div>
      </div>

      <div className="space-y-6 sm:space-y-5">

      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="company-name"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Company Name
          </label>
          <div className="mt-1 sm:col-span-2 sm:mt-0">

          <input
              name="customer"
              id="grid-customer-name"
              type="text"
              placeholder="Acme Inc."
              {...register("customer")}
              className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:max-w-xs sm:text-sm"
            />

            <div className="text-red-700">
              {errors.customer?.message}
            </div>
          </div>
        </div>


        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="contact-name"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Contact Name
          </label>
          <div className="mt-1 sm:col-span-2 sm:mt-0">
          <input
              name="contact_name"
              id="grid-contact-name"
              type="text"
              placeholder="Acme Inc."
              {...register("contact_name")}
              className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:max-w-xs sm:text-sm"
            />
            <div className="text-red-700">
              {errors.contact_name?.message}
            </div>
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="contact-name"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Email Address
          </label>
          <div className="mt-1 sm:col-span-2 sm:mt-0">
          <input
              name="email_address"
              id="grid-email_address"
              type="text"
              placeholder="tim@apple.com"
              {...register("email_address")}
              className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:max-w-xs sm:text-sm"
            />
            <div className="text-red-700">
              {errors.email_address?.message}
            </div>
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="phone_number"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Phone Number
          </label>
          <div className="mt-1 sm:col-span-2 sm:mt-0">
          <input
              name="phone_number"
              id="grid-phone_number"
              type="text"
              placeholder="(555) 1234 5678"
              {...register("phone_number")}
              className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:max-w-xs sm:text-sm"
            />
            <div className="text-red-700">
              {errors.phone_number?.message}
            </div>
          </div>
        </div>



      </div>
      </div>
      <div className="pt-5">
      <div className="flex justify-end">
        <button
          type="submit"
          className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-lasku-blue py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          disabled={isSubmitting}
        >
          {isSubmitting && (
            <span className="spinner-border spinner-border-sm mr-1"></span>
          )}
          {formState.isSubmitting ? "Creating..." : "Create"}
        </button>
      </div>
    </div>
    </form>
  );
}
