
export { SendInvoiceForm }

function SendInvoiceForm(props) {
    const isSubmitting = false;

    return (
        <>
            <div className="m-20 flex flex-col items-center">
                <p>Invoices cannot be edited once sent.</p>
                <button
                    type="submit"
                    className="mt-5 rounded-md border border-transparent bg-lasku-blue py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    disabled={isSubmitting}
                    onClick={props.action}
                    >
                    {isSubmitting && (
                        <span className="spinner-border spinner-border-sm mr-1"></span>
                    )}
                    {isSubmitting ? "Sending..." : "Send Invoice"}
                </button>
            </div>
        </>
    )
}
