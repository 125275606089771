import { Tabs } from "./Tabs";
import PageHeader from "_components/PageHeader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export { Billing };

function Billing() {
    return (
    <>
        <div className="col-span-full xl-col-span-6 bg-white shadow-lg rounded-sm border border-slate-200">
            <PageHeader title="Settings" />

            <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover
            theme="light"
            />

            <Tabs selected="Billing" />

            <form
            className="ml-6 mr-6 mb-6 space-y-8 divide-y divide-gray-200"
            >
            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
              <div className="space-y-6 sm:space-y-5">
                <div>
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Billing
                  </h3>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    Manage your account subscription for Lasku.
                  </p>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="stripe-account"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Subscription Level
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <p className="block text-sm text-gray-700 sm:mt-px sm:pt-2">Basic (Free)</p>
                    </div>
                  </div>
                </div>
            </div>
          </form>


        </div>
    </>
    )
}
