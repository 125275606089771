import { React } from "react";
import { ModalContainer } from "../_components/modalContainer";

export { CustomerModal };

function CustomerModal() {
  return (
    <>
      <ModalContainer type="CUSTOMER_CREATE" title="Create Customer" />
    </>
  );
}
