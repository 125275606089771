import PageHeader from "_components/PageHeader";

import { useSelector, useDispatch } from "react-redux";

import { settingsActions } from "_store";

import { useEffect } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Tabs } from "./Tabs";

export { Settings };

function Settings() {
  const dispatch = useDispatch();
  const { settings } = useSelector((x) => x.settings);

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    website: Yup.string(),
    company_name: Yup.string(),
    phone_number: Yup.string(),
    city: Yup.string(),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    register,
    handleSubmit,
    formState,
    setValue,
  } = useForm(formOptions);


  useEffect(() => {
    // eslint-disable-next-line
    dispatch(settingsActions.getAll());
  }, []);

  useEffect(() => {
    if (settings) {
      // format the routing number according to each country
      if (settings.bank_account_country === "Canada") {
        let routing_number_array = settings.bank_account_routing_number.split('-');
        let transit_number = routing_number_array[0];
        let institution_number = routing_number_array[1];
        setValue('bank_account_transit_number', transit_number);
        setValue('bank_account_institution_number', institution_number);
      }

      Object.entries(settings).forEach(([key, value]) => setValue(key, value));
      if (settings.addressable) {
        Object.entries(settings.addressable).forEach(([key, value]) =>
          // eslint-disable-next-line
          setValue(key, value)
        );
      }
    }
  }, [settings]);

  const { errors } = formState;

  function onSubmit({
    first_name,
    last_name,
    website,
    company_name,
    phone_number,
    line_1,
    line_2,
    city,
    province,
    country,
    postcode,
  }) {
    const params = {
      first_name: first_name,
      last_name: last_name,
      website: website,
      company_name: company_name,
      phone_number: phone_number,
      addressable_attributes: {
        line_1: line_1,
        line_2: line_2,
        city: city,
        province: province,
        country: country,
        postcode: postcode,
      },
    };
    dispatch(settingsActions.update(params)).then(
      toast.success("Settings updated")
    );
  }

  return (
    <>
      <div className="col-span-full xl-col-span-6 bg-white shadow-lg rounded-sm border border-slate-200">
        <PageHeader title="Settings" />

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
          theme="light"
        />

        <Tabs selected="General" />

        {settings && (
          <form
            className="ml-6 mr-6 mb-6 space-y-8 divide-y divide-gray-200"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
              <div className="space-y-6 sm:space-y-5">
                <div>
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Profile
                  </h3>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    This information will be included on your Proposals and
                    Invoices.
                  </p>
                </div>

                <div className="space-y-6 sm:space-y-5">
                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      First name
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      { settings.loading && (
                        <div className="animate-pulse w-25 bg-gray-300 h-6 rounded-md"></div>
                      )}
                      { !settings.loading && (
                        <input
                        type="text"
                        name="first-name"
                        id="first-name"
                        autoComplete="given-name"
                        {...register("first_name")}
                        className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:max-w-xs sm:text-sm"
                      />
                      )}
                      <div className="text-red-700">
                        {errors.first_name?.message}
                      </div>
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="last-name"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Last name
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                    { settings.loading && (
                        <div className="animate-pulse w-25 bg-gray-300 h-6 rounded-md"></div>
                      )}
                      { !settings.loading && (
                        <input
                          type="text"
                          name="last-name"
                          id="last-name"
                          autoComplete="family-name"
                          {...register("last_name")}
                          className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:max-w-xs sm:text-sm"
                        />
                      )}
                      <div className="text-red-700">
                        {errors.last_name?.message}
                      </div>
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="company-name"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Company name
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                    { settings.loading && (
                        <div className="animate-pulse w-25 bg-gray-300 h-6 rounded-md"></div>
                      )}
                      { !settings.loading && (
                      <input
                        type="text"
                        name="company-name"
                        id="company-name"
                        autoComplete="company"
                        {...register("company_name")}
                        className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:max-w-xs sm:text-sm"
                      />
                      )}
                      <div className="text-red-700">
                        {errors.company_name?.message}
                      </div>
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="website"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Website
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      { settings.loading && (
                        <div className="animate-pulse w-25 bg-gray-300 h-6 rounded-md"></div>
                      )}

                    { !settings.loading && (
                      <input
                        type="text"
                        name="website"
                        id="website"
                        autoComplete="website"
                        {...register("website")}
                        className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:max-w-xs sm:text-sm"
                      />
                    )}
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="phone_number"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Phone Number
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                    { settings.loading && (
                        <div className="animate-pulse w-25 bg-gray-300 h-6 rounded-md"></div>
                      )}
                      { !settings.loading && (
                      <input
                        type="text"
                        name="phone_number"
                        id="phone_number"
                        autoComplete="phone_number"
                        {...register("phone_number")}
                        className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:max-w-xs sm:text-sm"
                      />
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
                <div>
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Address
                  </h3>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    Use a permanent address where you can receive mail.
                  </p>
                </div>
                <div className="space-y-6 sm:space-y-5">
                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="country"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Country
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                    { settings.loading && (
                        <div className="animate-pulse w-25 bg-gray-300 h-6 rounded-md"></div>
                      )}
                      { !settings.loading && (
                      <select
                        id="country"
                        name="country"
                        autoComplete="country-name"
                        {...register("country")}
                        className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:max-w-xs sm:text-sm"
                      >
                        <option value="Australia">Australia</option>
                        <option value="Canada">Canada</option>
                        <option value="United States">United States</option>
                      </select>
                      )}
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="street-address"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Address Line 1
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                    { settings.loading && (
                        <div className="animate-pulse w-25 bg-gray-300 h-6 rounded-md"></div>
                      )}
                      { !settings.loading && (
                      <input
                        type="text"
                        name="street-address"
                        id="street-address"
                        autoComplete="street-address"
                        {...register("line_1")}
                        className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      />
                      )}
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="street-address"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Address Line 2
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                    { settings.loading && (
                        <div className="animate-pulse w-25 bg-gray-300 h-6 rounded-md"></div>
                      )}
                      { !settings.loading && (
                      <input
                        type="text"
                        name="street-address"
                        id="street-address"
                        autoComplete="street-address"
                        {...register("line_2")}
                        className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      />
                      )}
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="city"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      City
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                    { settings.loading && (
                        <div className="animate-pulse w-25 bg-gray-300 h-6 rounded-md"></div>
                      )}
                      { !settings.loading && (
                      <input
                        type="text"
                        name="city"
                        id="city"
                        autoComplete="address-level2"
                        {...register("city")}
                        className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:max-w-xs sm:text-sm"
                      />
                      )}
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="region"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      State / Province
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                    { settings.loading && (
                        <div className="animate-pulse w-25 bg-gray-300 h-6 rounded-md"></div>
                      )}
                      { !settings.loading && (
                      <input
                        type="text"
                        name="region"
                        id="region"
                        autoComplete="address-region"
                        {...register("province")}
                        className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:max-w-xs sm:text-sm"
                      />
                      )}
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="postal-code"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      ZIP / Postal code
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                    { settings.loading && (
                        <div className="animate-pulse w-25 bg-gray-300 h-6 rounded-md"></div>
                      )}
                      { !settings.loading && (
                      <input
                        type="text"
                        name="postal-code"
                        id="postal-code"
                        autoComplete="postal-code"
                        {...register("postcode")}
                        className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:max-w-xs sm:text-sm"
                      />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="pt-5">
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-lasku-blue py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  disabled={formState.isSubmitting}
                >
                  {formState.isSubmitting ? "Saving..." : "Save"}
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    </>
  );
}
