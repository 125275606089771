import { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "_components/PageHeader";
import { capitalizeString } from "common/formatters";
import { Table } from "_components";
import { customerActions } from "_store/customers.slice";
import { modalActions } from "_store";
import { CustomerModal } from "./CustomerModal";

export { Customer };

function Customer() {
  const dispatch = useDispatch();
  const { customers } = useSelector((x) => x.customers);

  const columns = useMemo(
    () => [
      {
        Header: "Customer",
        accessor: "name",
      },
      {
        Header: (props) => <div className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">Contact</div>,
        accessor: "contact_name",
        Cell: (props) => <div className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell"> {capitalizeString(props.value)} </div>,
      },
      {
        // Header: "Email",
        Header: (props) => <div className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">Email</div>,
        accessor: "email_address",
        Cell: (props) => <div className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">{props.value}</div>,
      },
      {
        Header: "Billed to date",
        accessor: "total_value",
      },
      {
        Header: "View",
        accessor: "external_id",
        Cell: (props) => (
          <div>
            {" "}
            <a
              href={"customers/" + props.value}
              className="text-lasku-blue hover:text-blue-900"
            >
              View<span className="sr-only">{props.value}</span>
            </a>{" "}
          </div>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    dispatch(customerActions.getAll());
  }, []);

  const data = useMemo(() => customers);

  return (
    <>
      <div className="col-span-full xl:col-span-6 bg-white shadow-lg rounded-sm border border-slate-200">
        <PageHeader
          title="Customers"
          actionTitle="Create Customer"
          link="/customers/create"
        />

        <div className="p-3">
          <div className="overflow-x-auto">
            <Table columns={columns} data={data} />
          </div>
        </div>

        <CustomerModal />
      </div>
    </>
  );
}
