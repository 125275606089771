import { useDispatch } from "react-redux";
import { modalActions } from "_store";
import { CreateCustomerForm } from "./forms/createCustomer.form";
import { CreateInvoiceForm } from "./forms/createInvoice.form";
import { CreateProjectForm } from "./forms/createProject.form";
import { SendInvoiceForm } from "./forms/sendInvoice.form";

export { Modal };

function Modal(props) {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(modalActions.setClosed());
  };

  function renderForm() {
    switch (props.type) {
      case "INVOICE_SEND":
        return <SendInvoiceForm action={props.action} />;
      case "INVOICE_CREATE":
        return <CreateInvoiceForm />;
      case "PROJECT_CREATE":
        return <CreateProjectForm />;
      case "CUSTOMER_CREATE":
        return <CreateCustomerForm />;
      default:
        return ""
    }
  }

  return (
    <>
      <div className="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
        <h5
          className="text-xl font-medium leading-normal text-gray-800"
          id="exampleModalLabel"
        >
          {props.title}
        </h5>
        <button
          type="button"
          onClick={handleClose}
          className="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </button>
      </div>

      {renderForm()}
    </>
  );
}
